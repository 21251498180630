<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <router-link to="/CadastroUsuario" v-slot="{ href, navigate }">
          <a :href="href" class="menu-link" @click="navigate">
            <v-btn class="mt-2 textStyle" tile color="#1dd1a1">
              <span class="textStyle">Cadastrar novo</span>
            </v-btn>
          </a>
        </router-link>

        <v-toolbar style="height: 6rem">
          <v-toolbar-title
            ><v-btn
              @click="consultarUsuarios"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultarUsuarios"
            v-model="consulta"
            class="mt-14"
            label="Pesquisar usuário por nome de acesso"
            color="green"
            filled
            solo-inverted
          ></v-text-field>
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`tooltip3-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`tooltip4-target`" triggers="hover">
              Próxima página
            </b-tooltip>
            <div class="mt-2">
              <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
            </div>
            <div>
              <v-btn
                :disabled="this.page == 0 || this.page == 1 ? true : false"
                @click="anteriorPage"
                id="tooltip3-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >
              <v-btn
                :disabled="
                  this.page == this.totalPages || this.totalPages == 0
                    ? true
                    : false
                "
                @click="proximoPage"
                id="tooltip4-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Nome de usuário</th>
                <th class="">Email</th>

                <th class="">Opções</th>
              </tr>
            </thead>
            <tbody v-for="user in tempUsuarios" :key="user.usuario.id">
              <tr>
                <td>{{ user.usuario.username }}</td>
                <td>{{ user.usuario.email }}</td>

                <td>
                  <b-tooltip
                    :target="`tooltip-target-${user.usuario.id}`"
                    triggers="hover"
                  >
                    Visualizar dados do usuário
                  </b-tooltip>
                  <b-tooltip
                    :target="`tooltip2-target-${user.usuario.id}`"
                    triggers="hover"
                  >
                    Atualizar dados do usuário
                  </b-tooltip>
                  <router-link
                    :to="{
                      name: 'visualizarDadosUsuario',
                      params: { id: user.usuario.id },
                    }"
                    v-slot="{ href, navigate }"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn
                        :id="`tooltip-target-${user.usuario.id}`"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'editarDadosUsuario',
                      params: { id: user.usuario.id },
                    }"
                    v-slot="{ href, navigate }"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn
                        :id="`tooltip2-target-${user.usuario.id}`"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-edit</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempUsuarios.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

export default {
  name: "PainelDeContas",
  async created() {
    try {
      const response = await ambulatorio.listUsuariosPaged(1);
      this.totalPages = response.data.total_pages;
      this.usuarios = response.data.usuarios;
      this.tempUsuarios = response.data.usuarios;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      totalPages: 1,
      page: 1,
      usuarios: [],
      tempUsuarios: [],
      consulta: "",
    };
  },

  methods: {
    consultarUsuarios: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const response = await ambulatorio.listUsuariosPaged(1);
        this.usuarios = response.data.usuarios;
        this.tempUsuarios = response.data.usuarios;
      } else {
        const data = {
          filter: {
            username: this.consulta,
            email: this.consulta,
            "setores.descricao": this.consulta,
            usuario_de_type: this.consulta,
          },
          page: 1,
          size: 10,
        };

        const resultBusca = await ambulatorio.buscaUsuarios(data);

        this.usuarios = resultBusca.data.resultado;
        this.tempUsuarios = resultBusca.data.resultado;
      }
    },

    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.listUsuariosPaged(this.page);
          this.usuarios = response.data.usuarios;
          this.tempUsuarios = response.data.usuarios;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.listUsuariosPaged(this.page);
          this.usuarios = response.data.usuarios;
          this.tempUsuarios = response.data.usuarios;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Painel de contas" }]);
  },
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
